<template>
    <div class="modal profile_modal share_playbook" v-if="modelValue">
        <div class="modal_container">
            <div class="modal_header border-bottom">
                <h1 class="m-0">Sequence Analytics</h1>
                <button class="close_btn" @click="closeModal()"><i class="fas fa-times"></i></button>
                <ul class="tab_row">
                    <li :class="tab === 'email' ? 'active' : ''" @click="tab = 'email'">Email</li>
                    <li :class="tab === 'sms' ? 'active' : ''" @click="tab = 'sms'">SMS</li>
                </ul>
            </div>
            <div class="modal_body">
                <div v-if="tab === 'email'" class="tab_wpr">
                    <ul class="analytics">
                        <li>
                            <div class="analytics_card">
                                <div class="score_circle">
                                    <div class="inner_circle">
                                        <div class="score" style="transform: scale(0.8);">{{ sequence.sends }}</div>
                                    </div>
                                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="50" height="50">
                                        <circle cx="25" cy="25" r="23.5" stroke-linecap="round" fill="none" stroke-width="3px" stroke="#2F7FED" :stroke-dasharray="202" :stroke-dashoffset="0"/>
                                    </svg>
                                </div>
                                <h4>Total Sent</h4>
                            </div>
                        </li>
                        <li>
                            <div class="analytics_card">
                                <div class="score_circle">
                                    <div class="inner_circle">
                                        <div class="score" style="transform: scale(0.8);">{{ sequence.subscribers }}</div>
                                    </div>
                                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="50" height="50">
                                        <circle cx="25" cy="25" r="23.5" stroke-linecap="round" fill="none" stroke-width="3px" stroke="#2F7FED" :stroke-dasharray="202" :stroke-dashoffset="0"/>
                                    </svg>
                                </div>
                                <h4>Subscribers</h4>
                            </div>
                        </li>
                        <li>
                            <div class="analytics_card">
                                <div class="score_circle">
                                    <div class="inner_circle">
                                        <div class="score" style="transform: scale(0.8);">{{ sequence.unsubscribes }}<span>%</span></div>
                                    </div>
                                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="50" height="50">
                                        <circle cx="25" cy="25" r="23.5" stroke-linecap="round" fill="none" stroke-width="3px" stroke="#2F7FED" :stroke-dasharray="202" :stroke-dashoffset="202 - (sequence.unsubscribes_rate * 2.02)"/>
                                    </svg>
                                </div>
                                <h4>Unsubscribed</h4>
                            </div>
                        </li>
                        <li>
                            <div class="analytics_card">
                                <div class="score_circle">
                                    <div class="inner_circle">
                                        <div class="score" style="transform: scale(0.8);">{{ sequence.open_rate }}<span>%</span></div>
                                    </div>
                                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="50" height="50">
                                        <circle cx="25" cy="25" r="23.5" stroke-linecap="round" fill="none" stroke-width="3px" stroke="#2F7FED" :stroke-dasharray="202" :stroke-dashoffset="202 - (sequence.open_rate * 2.02)"/>
                                    </svg>
                                </div>
                                <h4>Open Rate</h4>
                            </div>
                        </li>
                        <li>
                            <div class="analytics_card">
                                <div class="score_circle">
                                    <div class="inner_circle">
                                        <div class="score" style="transform: scale(0.8);">{{ sequence.clicked_rate }}<span>%</span></div>
                                    </div>
                                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="50" height="50">
                                        <circle cx="25" cy="25" r="23.5" stroke-linecap="round" fill="none" stroke-width="3px" stroke="#2F7FED" :stroke-dasharray="202" :stroke-dashoffset="202 - (sequence.clicked_rate * 2.02)"/>
                                    </svg>
                                </div>
                                <h4>Click Rate</h4>
                            </div>
                        </li>
                        <li>
                            <div class="analytics_card">
                                <div class="score_circle">
                                    <div class="inner_circle">
                                        <div class="score" style="transform: scale(0.8);">{{ sequence.undelivered_rate }}<span>%</span></div>
                                    </div>
                                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="50" height="50">
                                        <circle cx="25" cy="25" r="23.5" stroke-linecap="round" fill="none" stroke-width="3px" stroke="#2F7FED" :stroke-dasharray="202" :stroke-dashoffset="202 - (sequence.clicked_rate * 2.02)"/>
                                    </svg>
                                </div>
                                <h4>Undelivered</h4>
                            </div>
                        </li>
                    </ul>
                    <div class="result_wpr">
                        <div class="actions">
                            <ul>
                                <li class="optionDrops sort_list" @click="toggleEmailPerPageFilter()" v-click-outside="closePerPageDropdown">
                                    {{ emailParams.per_page }} <i  class="fas fa-angle-down"></i>
                                    <page-filter v-model="emailParams.per_page" :type="2" :is-dropdown="true" ref="analyics-email-filter" />
                                </li>
                                <li>
                                    <input type="text" placeholder="Search" :disabled="analyticsLoader" ref="search" @input="isTypingEmail = true" v-model.trim="emailParams.search" />
                                    <i class="fas fa-search"></i>
                                </li>
                                <li class="list_info">
                                    {{ emailAnalytics.from ? emailAnalytics.from : 0 }} - {{ emailAnalytics.to ? emailAnalytics.to : 0 }} of <span>{{ emailAnalytics.total ? emailAnalytics.total : 0 }}</span>
                                </li>
                            </ul>
                        </div>
                        <table class="standard">
                            <thead>
                                <tr>
                                    <th>Subject</th>
                                    <th>Open Rate</th>
                                    <th>Click Rate</th>
                                    <th>Sends</th>
                                    <th>Unsubscribed</th>
                                    <th>Undelivered</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(email, e) of emailAnalytics.data" :key="e">
                                    <td>{{ email.subject }}</td>
                                    <td>{{ email.open_rate }}%</td>
                                    <td>{{ email.clicked_rate }}%</td>
                                    <td>{{ email.sends }}</td>
                                    <td>{{ email.unsubscribes }}</td>
                                    <td>{{ email.email_failed_count }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="pagination pb-4 mt-4">
                        <pagination v-model="emailParams.page" :pages="emailAnalytics.last_page" @update:modelValue="handleEmailPagination" />
                    </div>
                </div>
                <div v-if="tab === 'sms'" class="tab_wpr">
                    <ul class="analytics">
                        <li>
                            <div class="analytics_card">
                                <div class="score_circle">
                                    <div class="inner_circle">
                                        <div class="score" style="transform: scale(0.8);">{{ sequence.sms_sends }}</div>
                                    </div>
                                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="50" height="50">
                                        <circle cx="25" cy="25" r="23.5" stroke-linecap="round" fill="none" stroke-width="3px" stroke="#2F7FED" :stroke-dasharray="202" :stroke-dashoffset="202"/>
                                    </svg>
                                </div>
                                <h4>Total Sent</h4>
                            </div>
                        </li>
                        <li>
                            <div class="analytics_card">
                                <div class="score_circle">
                                    <div class="inner_circle">
                                        <div class="score" style="transform: scale(0.8);">{{ sequence.sms_delivered }}</div>
                                    </div>
                                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="50" height="50">
                                        <circle cx="25" cy="25" r="23.5" stroke-linecap="round" fill="none" stroke-width="3px" stroke="#2F7FED" :stroke-dasharray="202" :stroke-dashoffset="202 - (sequence.sms_delivered_rate * 2.02)"/>
                                    </svg>
                                </div>
                                <h4>Delivered</h4>
                            </div>
                        </li>
                        <li>
                            <div class="analytics_card">
                                <div class="score_circle">
                                    <div class="inner_circle">
                                        <div class="score" style="transform: scale(0.8);">{{ sequence.sms_failed }}<span>%</span></div>
                                    </div>
                                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="50" height="50">
                                        <circle cx="25" cy="25" r="23.5" stroke-linecap="round" fill="none" stroke-width="3px" stroke="#2F7FED" :stroke-dasharray="202" :stroke-dashoffset="202 - (sequence.sms_bounced_rate * 2.02)"/>
                                    </svg>
                                </div>
                                <h4>Bounced</h4>
                            </div>
                        </li>
                        <li>
                            <div class="analytics_card">
                                <div class="score_circle">
                                    <div class="inner_circle">
                                        <div class="score" style="transform: scale(0.8);">{{ sequence.sms_delivered_rate }}<span>%</span></div>
                                    </div>
                                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="50" height="50">
                                        <circle cx="25" cy="25" r="23.5" stroke-linecap="round" fill="none" stroke-width="3px" stroke="#2F7FED" :stroke-dasharray="202" :stroke-dashoffset="202 - (sequence.sms_delivered_rate * 2.02)"/>
                                    </svg>
                                </div>
                                <h4>Delivered Rate</h4>
                            </div>
                        </li>
                        <li>
                            <div class="analytics_card">
                                <div class="score_circle">
                                    <div class="inner_circle">
                                        <div class="score" style="transform: scale(0.8);">{{ sequence.sms_bounced_rate }}<span>%</span></div>
                                    </div>
                                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="50" height="50">
                                        <circle cx="25" cy="25" r="23.5" stroke-linecap="round" fill="none" stroke-width="3px" stroke="#2F7FED" :stroke-dasharray="202" :stroke-dashoffset="202 - (sequence.sms_bounced_rate * 2.02)"/>
                                    </svg>
                                </div>
                                <h4>Bounce Rate</h4>
                            </div>
                        </li>
                        <li>
                            <div class="analytics_card">
                                <div class="score_circle">
                                    <div class="inner_circle">
                                        <div class="score" style="transform: scale(0.8);">{{ sequence.sms_bounced_rate }}<span>%</span></div>
                                    </div>
                                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="50" height="50">
                                        <circle cx="25" cy="25" r="23.5" stroke-linecap="round" fill="none" stroke-width="3px" stroke="#2F7FED" :stroke-dasharray="202" :stroke-dashoffset="202 - (sequence.sms_bounced_rate * 2.02)"/>
                                    </svg>
                                </div>
                                <h4>Undelivered</h4>
                            </div>
                        </li>
                    </ul>
                    <div class="result_wpr">
                        <div class="actions">
                            <ul>
                                <li class="optionDrops sort_list" @click="toggleSMSPerPageFilter()" v-click-outside="closePerPageDropdown">
                                    {{ smsParams.per_page }} <i  class="fas fa-angle-down"></i>
                                    <page-filter v-model="smsParams.per_page" :type="2" :is-dropdown="true" ref="analyics-sms-filter" />
                                </li>
                                <li>
                                    <input type="text" placeholder="Search" :disabled="analyticsLoader" ref="search" @input="isTypingSMS = true" v-model.trim="smsParams.search" />
                                    <i class="fas fa-search"></i>
                                </li>
                                <li class="list_info">
                                    {{ smsAnalytics.from ? smsAnalytics.from : 0 }} - {{ smsAnalytics.to ? smsAnalytics.to : 0 }} of <span>{{ smsAnalytics.total ? smsAnalytics.total : 0 }}</span>
                                </li>
                            </ul>
                        </div>
                        <table class="standard">
                            <thead>
                                <tr>
                                    <th>SMS</th>
                                    <th>Delivered</th>
                                    <th>Bounced</th>
                                    <th>Delivered Rate</th>
                                    <th>Bounced Rate</th>
                                    <th>Undelivered</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(sms, s) of smsAnalytics.data" :key="s">
                                    <td>{{ sms.sms }}</td>
                                    <td>{{ sms.sms_sends }}</td>
                                    <td>{{ sms.sms_failed }}</td>
                                    <td>{{ sms.sms_delivered_rate }}%</td>
                                    <td>{{ sms.sms_bounced_rate }}%</td>
                                    <td>{{ sms.sms_bounced_rate }}%</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="pagination pb-4 mt-4">
                        <pagination v-model="smsParams.page" :pages="smsAnalytics.last_page" @update:modelValue="handleSMSPagination" />
                    </div>
                </div>
            </div>
            <div class="modal_footer">
                <button class="btn cancel_btn" @click="closeModal()">Cancel</button>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex'

    export default {
        name: 'Sequence Analytics',

        data () {
            return {
                tab: 'email',
                emailParams: {
                    per_page: 5,
                    page: 1,
                    search: '',
                    type: 'email',
                },
                smsParams: {
                    per_page: 5,
                    page: 1,
                    search: '',
                    type: 'sms',
                },
                isTypingEmail: false,
                isTypingSMS: false,
            };
        },

        props: {
            modelValue: Boolean,
            sequence: Object,
        },

        emits: ['update:modelValue'],

        watch: {
            modelValue (value) {
                if (value) {
                    const vm = this;

                    vm.emailParams.page         = 1;
                    vm.emailParams.search       = '';
                    vm.emailParams.sequence_id  = vm.sequence.id;
                    vm.smsParams.page           = 1;
                    vm.smsParams.search         = '';
                    vm.smsParams.sequence_id    = vm.sequence.id;

                    vm.getAnalytics(vm.emailParams);
                    vm.getAnalytics(vm.smsParams);
                }
            },

            'emailParams.search' (val) {
                const vm = this;

                setTimeout(() => {
                    vm.isTyping = false;
                }, 1500);
            },

            isTypingEmail (val) {
                const vm = this;

                if (!val) {
                    if (vm.emailParams.search != null) {
                        if (vm.emailParams.search.length >= 2) {
                            vm.emailParams.page = 1;
                            vm.getAnalytics(vm.emailParams);
                        }

                        if (vm.emailParams.search.length === 0) {
                            vm.emailParams.page = 1;
                            vm.getAnalytics(vm.emailParams);
                        }
                    }
                }
            },

            'emailParams.per_page' () {
                const vm = this;

                vm.emailParams.page      = 1;

                vm.getAnalytics(vm.emailParams);
            },

            'smsParams.search' (val) {
                const vm = this;

                setTimeout(() => {
                    vm.isTyping = false;
                }, 1500);
            },

            isTypingSMS (val) {
                const vm = this;

                if (!val) {
                    if (vm.smsParams.search != null) {
                        if (vm.smsParams.search.length >= 2) {
                            vm.smsParams.page = 1;
                            vm.getAnalytics(vm.smsParams);
                        }

                        if (vm.smsParams.search.length === 0) {
                            vm.smsParams.page = 1;
                            vm.getAnalytics(vm.smsParams);
                        }
                    }
                }
            },

            'smsParams.per_page' () {
                const vm = this;

                vm.smsParams.page      = 1;

                vm.getAnalytics(vm.smsParams);
            },
        },

        computed: mapState({
            emailAnalytics: state => state.sequenceModule.emailAnalytics,
            smsAnalytics: state => state.sequenceModule.smsAnalytics,
            analyticsLoader: state => state.sequenceModule.analyticsLoader,
        }),

        methods: {
            ...mapActions({
                getAnalytics: 'sequenceModule/getAnalytics',
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            toggleEmailPerPageFilter () {
                const vm = this;

                const filter = vm.$refs['analyics-email-filter'];

                if (filter) {
                    filter.dropdown = !filter.dropdown;
                }
            },

            toggleSMSPerPageFilter () {
                const vm = this;

                const filter = vm.$refs['analyics-sms-filter'];

                if (filter) {
                    filter.dropdown = !filter.dropdown;
                }
            },

            closePerPageDropdown () {
                const vm = this;

                const emailFilter = vm.$refs['analyics-email-filter'];
                const smsFilter   = vm.$refs['analyics-sms-filter'];

                if (emailFilter) {
                    emailFilter.dropdown = false;
                }

                if (smsFilter) {
                    smsFilter.dropdown = false;
                }
            },

            handleEmailPagination (page) {
                const vm = this;

                vm.emailParams.page = page;
                vm.getAnalytics(vm.emailParams);
            },

            handleSMSPagination (page) {
                const vm = this;

                vm.smsParams.page = page;
                vm.getAnalytics(vm.smsParams);
            },
        },
    }
</script>

<style scoped>
    .analytics {
        display: flex;
        flex-wrap: wrap;
        margin: 15px -10px 0 -10px;
    }

    .analytics li {
        padding: 10px;
        width: 16.666%;
    }

    .analytics_card {
        border: 1px solid #eaeaea;
        border-radius: 8px;
        width: 100%;
        height: 100%;
        padding: 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
        box-shadow: none;
        transition: all 0.3s ease-in-out;
    }

    .analytics_card .score_circle {
        width: 50px;
        height: 50px;
        padding: 3px;
    }

    .analytics_card .score_circle .inner_circle .score {
        font-size: 13px;
        line-height: 16px;
    }

    .analytics_card h4 {
        font-size: 12px;
        line-height: 15px;
        color: #121525;
        font-weight: 400;
        margin-top: 10px;
        text-align: center;
    }

    .analytics_card:hover {
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    }

    .share_playbook .modal_container {
        height: 640px;
    }

    .share_playbook .modal_container .modal_header {
        padding: 20px 30px 0;
    }

    .share_playbook .modal_container .modal_body {
        padding: 0 30px;
        flex-direction: column;
    }

    .share_playbook .modal_container .modal_footer {
        border-radius: 0 0 12px 12px;
    }

    .share_playbook .tab_row {
        padding: 20px 0 0 0;
        display: flex;
        gap: 30px;
    }

    .share_playbook .tab_row li {
        padding-bottom: 12px;
        position: relative;
        cursor: pointer;
        font-size: 13px;
        line-height: 18px;
        font-weight: 400;
        color: #121525;
        overflow: hidden;
    }
    .share_playbook .tab_row li:after{
        position: absolute;
        content: '';
        left: -100%;
        bottom: 0;
        width: 100%;
        border-bottom: 1px solid #2F80ED;
        transition: all 0.3s ease-in-out;
    }

    .share_playbook .tab_row li.active {
        color: #2f7eed;
    }
    .share_playbook .tab_row li.active:after{
        left: 0;
    }

    .share_playbook .tab_wpr {
        width: 100%;
    }

    .share_playbook .result_wpr {
        width: 100%;
    }
    .result_wpr table td:first-child, .result_wpr table th:first-child{
        width: auto;
        text-align: left;
        padding: 10px 15px;
    }
    .result_wpr table td:not(:first-child), .result_wpr table th:not(:first-child){
        width: 15%;
        text-align: center;
    }
    @media(max-width: 767px){
        .analytics li{
            width: 33.333%;
        }
    }
</style>
